import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";

const LoaderContainer = styled.div`
  ${tw`fixed inset-0 flex items-center justify-center bg-white z-50`}
`;

const LoaderLogo = styled(motion.img)`
  ${tw`w-12 h-12`} /* Mantén un tamaño adecuado para dispositivos móviles */
`;

const loaderVariants = {
  animate: {
    scale: [1, 1.5, 1], /* Escalado más suave */
    rotate: [0, 360], /* Añade rotación para hacer la animación más dinámica */
    transition: {
      repeat: Infinity,
      duration: 1.5, /* Hacerlo un poco más rápido */
      ease: "easeInOut"
    }
  }
};

const LoaderMessage = styled.div`
  ${tw`text-sm font-medium text-gray-600`} 
  position: absolute;
  bottom: 16px; /* Sustituimos bottom-4 por su equivalente en píxeles */
`;

const Loader = () => {
  return (
    <LoaderContainer aria-live="polite" role="status">
      <LoaderLogo src="/chocholoaderblack_resultado.webp" alt="Cargando..." variants={loaderVariants} animate="animate" />
    </LoaderContainer>
  );
};

export default Loader;
