import React, { useState, useEffect } from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './flags.css';
import { FaWhatsapp, FaArrowUp } from 'react-icons/fa';
import 'tailwindcss/tailwind.css';

import MainLandingPage from "MainLandingPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import AboutUs from "pages/AboutUs.js";
import ContactUs from "pages/ContactUs.js";
import BlogIndex from "pages/BlogIndex.js";
import Form from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import HeaderComponent from "components/headers/light.js";
import Loader from "./components/Loader";

export default function App() {
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState('es');

  // Cambia el idioma
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  // Abre WhatsApp
  const openWhatsApp = () => {
    window.open('https://wa.me/+5491160575569', '_blank');
  };

  // Desplazarse hacia arriba
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Controla la visibilidad del botón de desplazamiento
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollButton(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Simulación de carga inicial
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <GlobalStyles />
      <Router>
        <AppContent
          changeLanguage={changeLanguage}
          activeLanguage={activeLanguage}
          showScrollButton={showScrollButton}
          openWhatsApp={openWhatsApp}
          scrollToTop={scrollToTop}
        />
      </Router>
    </>
  );
}

function AppContent({
  changeLanguage,
  activeLanguage,
  showScrollButton,
  openWhatsApp,
  scrollToTop,
}) {
  const location = useLocation(); // Hook para obtener la ubicación actual

  return (
    <>
      <HeaderComponent />

      {/* Renderiza Hero solo en la ruta principal */}
      {location.pathname === "/" && <Hero />}

      <Routes>
        <Route path="/" element={<MainLandingPage />} />
        <Route path="/quienes-somos" element={<AboutUs />} />
        <Route path="/ubicacion" element={<ContactUs />} />
        <Route path="/servicios" element={<BlogIndex />} />
        <Route path="/preguntas" element={<Form />} />
      </Routes>

      {/* Contenedor para los botones de idioma */}
      <div className="floating-language-buttons">
        <button
          onClick={() => changeLanguage('es')}
          className={activeLanguage === 'es' ? 'active' : ''}
        >
          ES
        </button>
        <button
          onClick={() => changeLanguage('en')}
          className={activeLanguage === 'en' ? 'active' : ''}
        >
          EN
        </button>
      </div>

      {/* Botones flotantes de WhatsApp y scroll */}
      <div className="floating-buttons">
        <button onClick={openWhatsApp} className="whatsapp-button">
          <FaWhatsapp size={50} color="#25D366" />
        </button>

        {/* Botón de flecha hacia arriba */}
        {showScrollButton && (
          <button onClick={scrollToTop} className="scroll-to-top-button visible">
            <FaArrowUp size={50} color="#000" />
          </button>
        )}
      </div>
    </>
  );
}
